import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic1.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic2.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic3.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic4.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic5.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic6.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic7.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic8.jpeg",
];

export default function SplishSplash() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Splish - Splash</h1>

        <p>
          <strong>
            “We should all do what, in the long run, gives us joy, even if it is
            only sorting the unsorted”
          </strong>{" "}
          <br />
          <br />
          Keeping this in mind , the tiny tots of the Pre-Primary Department of
          Chinmaya Vidyalaya , New - Delhi enjoyed participating in the ‘Splish
          - Splash’ activity on 21st July 2023 . The passionate learners engaged
          with great zeal and elation in the shape, number and alphabet sorting
          activity .The learners were encouraged to pick out several letters ,
          numbers and shapes floating on water and drop in an empty tub. The
          activity aimed at enhancing fine - motor skills, identification,
          hand-eye coordination and organisational skills. The youngsters
          relished the activity immensely.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
